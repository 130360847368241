/* eslint-disable no-unreachable */
import axios from "axios";
import config from "../config/properties";


const ORDERDETAIL_SUCCESS = 'ORDERDETAIL_SUCCESS';
const ORDERDETAIL_FAILURE = 'ORDERDETAIL_FAILURE';

const APPLIORDERDETAIL_SUCCESS = 'APPLIORDERDETAIL_SUCCESS';
const APPLIORDERDETAIL_FAILURE = 'APPLIORDERDETAIL_FAILURE';

export function getallcustomerorderslist(data) {
  try {

    return async () => {

      return await axios.post(config.base_url + "customer/getAllCustomerOrdersById", data

      )
    }

  } catch (e) {

  }
}

// export function orderdetailsvalue(data) { 
//   try {

//     return async () => {

//       return await axios.post(config.base_url + "customer/getCustomerOrderDetails", data

//       )
//     }

//   } catch (e) {

//   }
// }


export function orderdetailsvalue(data)
{
    return async (dispatch) => {
    dispatch(orderdetailsRequest('Request'))
        function onSuccess(success)
        {
            dispatch({type:ORDERDETAIL_SUCCESS,success})
            return success
        }
    
        function onError(error)
        {
            dispatch({type:ORDERDETAIL_FAILURE,error})
            return error	
        }
        
        try{
            const success = await axios.post(config.base_url+"customer/getCustomerOrderDetails",data
            ,
            {headers: {
                'Content-Type': 'application/json',
        }}
            
            )
            console.log(success)
            return onSuccess(success);
    }
        catch(error)
        {
            return onError(error);
        }
    
    }
    }

export const designdraftapproveORreject = (data) => {
  return async () => {
    try {
      const designDraftRequest = await axios.post(config.base_url + "customer/approveOrRejectDesignDraftByCustomer", data, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(designDraftRequest, "bookDemoRequestbookDemoRequest")
        return designDraftRequest;
    } catch (e) {}
  }
}

export const getAllListingDetailsForHome = (data) => {
  return async () => {
    try {
      const getAllListingDetailsForHomeRequest = await axios.post(config.base_url + "customer/getAllListingDetailsForHome", data, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(getAllListingDetailsForHomeRequest, "getAllListingDetailsForHomeRequest")
        return getAllListingDetailsForHomeRequest;
    } catch (e) {}
  }
}

export const digisignurlgeneration = (data) => {
  return async () => {
    try {
      const digisigncontract = await axios.post(config.base_url + "customer/eversignUrlGeneration", data, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(digisigncontract, "digisigncontract")
        return digisigncontract;
    } catch (e) {}
  }
}

export function documentaftersign(data) {
  try {

    return async () => {

      return await axios.post(config.base_url + "customer/signContractDocumentByCustomer", data

      )
    }

  } catch (e) {

  }
}

export function deliveryfittingappointment(data) {
  try {
      return async () => {
      return await axios.post(config.base_url + "customer/requestForDeliveryAndFittingAppiontment", data
        )
    }
  } catch (e) {
  }
}

export function ordercheckliststatus(param) {
  try {
      return async () => {
      return await axios.post(config.base_url + "customer/actualOrderChecklistStatusList", param )
    }
  } catch (e) {
  }
}

export function getEstimatedTime(data) {
  try {

    return async () => {

      return await axios.post(config.base_url + "track/getEstimatedTimeOfArrival", data

      )
    }

  } catch (e) {

  }
}

// for aditional orders api [appliance task]
export function getAditionalorderslist(data) {
  return async (dispatch) => {
    dispatch(appliorderdetailsRequest('Request'))
    function onSuccess(success)
    {
      dispatch({type: APPLIORDERDETAIL_SUCCESS, success})
      return success
    }

    function onError(error) {
      dispatch({type: APPLIORDERDETAIL_FAILURE, error})
    }
    try {
      const success = await axios.post(config.base_url + "customer/getAdditionalOrders", data, {headers: {
        'Content-Type': 'application/json',
      }})
      return onSuccess(success);
    }
    catch(error){
      return onError(error);
    }
  }
}

export function approveRejectItemsCustomer(data) {

  console.log("inside accept and reject api :", data)
  try {

    return async () => {

      return await axios.post(config.base_url + "customer/approveOrRejectItemsByCustomer", data
      )
    }

  } catch (e) {

  }
}

const orderdetailsRequest=(response)=>({
  type:"ORDERDETAIL_REQUESTING",
  response
})

const appliorderdetailsRequest=(response)=>({
  type:"APPLIORDERDETAIL_REQUESTING",
  response
})