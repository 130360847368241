import React,{useState, useEffect} from 'react';
import { useSelector, useDispatch} from "react-redux";
import Translation from "../../language/translate"
import { ClipLoader } from 'react-spinners';
import {getKitchenShape, designKitchen, fnGetDimensions} from "../../actions/bookFreeConsultationActions"
import Tick from "../../images/tick.png"
import {logoutApp} from "../../actions/loginactions";
import { useHistory } from "react-router";
import { Slider } from 'antd';
import "./index.css"

const DesignKitchen=(props)=>{

const history = useHistory();

const details = useSelector(state => state);

const dispatch = useDispatch();

const [sliderValue, setSliderValue]=useState(details.designKitchen.kitchenSizeShape.size? details.designKitchen.kitchenSizeShape.size: props.orderData.kitchen_size? Number(props.orderData.kitchen_size) : 0)

const [loader, setloader]=useState(false)

const [kitchenShapes, setKitchenShapes] = useState([])

const [dimensions, setDimensions] = useState([])

const [mandatoryError, setMandatoryError] = useState(false)

const [shapeId, setShapeId] =useState(details.designKitchen.kitchenSizeShape.shapeId ? details.designKitchen.kitchenSizeShape.shapeId : props.orderData.kitchen_shape
    ? props.orderData.kitchen_shape : "")

const [shapeSelected, setShapeSelected] = useState(details.designKitchen.kitchenSizeShape.shape ? details.designKitchen.kitchenSizeShape.shape : props.orderData.kitchen_shape_title? props.orderData.kitchen_shape_title : "")

const [shapeSelectedArabic, setShapeSelectedArabic] = useState(details.designKitchen.kitchenSizeShape.shapeArabic ? details.designKitchen.kitchenSizeShape.shapeArabic : props.orderData.kitchen_shape_title_arabic? props.orderData.kitchen_shape_title_arabic : "")

const [sizeUnit, setSizeUnit] = useState(details.designKitchen.kitchenSizeShape.sizeUnit !== undefined ? details.designKitchen.kitchenSizeShape.sizeUnit : props.orderData.kitchen_size_dimension? props.orderData.kitchen_size_dimension : "Meter")

const sliderValueFun = (value) => setSliderValue(value);

function formatter(value) {
sliderValueFun(value === 30 ? `${value}` : `${value}`)
}

useEffect(() => {
setloader(true)
onLoad()
getDimensions()
}, []);

const onLoad = async () => {
const kitchenShapes = await dispatch(getKitchenShape());
console.log(kitchenShapes);
if (kitchenShapes.data.status_code == 200) {
setloader(false)
setKitchenShapes(kitchenShapes.data.list)
}
else if(kitchenShapes.data.status_code == 408 || kitchenShapes.data.status_code == 402){
    let obj = {
        "customer_id": localStorage.getItem("customer_id"),
        "deviceType": "web"
    }
    const logoutResponse = await dispatch(logoutApp(obj));
    if(logoutResponse.data.status_code == 200){
        
        localStorage.clear();
    
        history.push({pathname:  "/"})
        window.location.reload()
        
        
    }
}
else{
setloader(false)
}
}

const getDimensions = async () => {
    const Dimensions = await dispatch(fnGetDimensions());
    console.log(kitchenShapes);
    if (Dimensions.data.status_code == 200) {
    setloader(false)
    setDimensions(Dimensions.data.list)
    }
    else if(Dimensions.data.status_code == 408 || Dimensions.data.status_code == 402){
        let obj = {
            "customer_id": localStorage.getItem("customer_id"),
            "deviceType": "web"
        }
        const logoutResponse = await dispatch(logoutApp(obj));
        if(logoutResponse.data.status_code == 200){
            
            localStorage.clear();
        
            history.push({pathname:  "/"})
            window.location.reload()
            
            
        }
    }
    else{
    setloader(false)
    }
    }

const selectShape =(shapeType, shapeTypeArabic, shapeId)=>{
setShapeSelectedArabic(shapeTypeArabic)
setShapeSelected(shapeType)
setShapeId(shapeId)
}

const continueButtonClick=()=>{
    console.log(sliderValue, shapeSelected, shapeSelectedArabic, sizeUnit, shapeId) 
    if(sliderValue !== "0" && shapeSelected !==undefined){
    dispatch(designKitchen(sliderValue, shapeSelected, shapeSelectedArabic, sizeUnit, shapeId))
    setMandatoryError(false)
    props.handleNext()
    }
    else{
        setMandatoryError(true)
    }
}

const selectSizeUnit =(e)=>{
    console.log(e.target.value)
    setSizeUnit(e.target.value)
}

console.log("props.orderData :", props.orderData, "details.designKitchen :", details.designKitchen)
return (
<React.Fragment>
    {console.log(sliderValue)}
    <div className={mandatoryError ? "design-kitchen design-kitchen-with-error-padding": "design-kitchen design-kitchen-padding"} align={details.Languages =="ar"?"right": ""}>
        <div className="header-title">
            {Translation[details.Languages]["DesignKitchen"]}
        </div>
        <div className="header-description">
            {Translation[details.Languages]["SelectBelow"]}
        </div>
        <div className="kitchen-image-div">
            <div className="kitchen-title">
                {Translation[details.Languages]["KitchenSize"]}<span className="starRed">*</span>
            </div>
            {sliderValue >=10 && sliderValue <=19?<img data-src="https://niuli-images.s3.ap-south-1.amazonaws.com/Medium.svg" className="lazyload imgnew_resfix" alt="kitchen" style={{padding: "30px"}} />
            :sliderValue >=20 ?<img data-src="https://s3.ap-south-1.amazonaws.com/niuli-images/1603735499693-niuli-kitchen-size.png" className="lazyload imgnew_resfix" alt="kitchen" style={{padding: "30px"}} />
            :<img data-src="https://niuli-images.s3.ap-south-1.amazonaws.com/Small.svg" className="lazyload imgnew_resfix" alt="kitchen" style={{padding: "30px"}} />}
            {/* <div className={details.Languages=="en"?"slider-div":"slider-divar"}> */}
            <div className={details.Languages=="en"?"slider-div":"slider-div"}>
                <Slider tipFormatter={formatter} min={0} max={30} defaultValue ={sliderValue} className="kitchen-slider" tooltipVisible={false} />
            </div>
            <div className={details.Languages=="en"?"sliver-value-div":"sliver-value-divar"}>
                {/* {sliderValue+ " sq ft"} */}
                {sliderValue}
                    <select  onClick= {e => selectSizeUnit(e)} value={sizeUnit} style={details.Languages == "en" ? {marginLeft: "10px", padding: "5px 0px"} : {marginRight: "10px", padding: "5px"}}>
                        {/* <option value="Square Feet">{Translation[details.Languages]["Squarefeet"]}</option>
                        <option value="Square Meter">{Translation[details.Languages]["Squaremeter"]}</option>
                        <option value="Linear Meter">{Translation[details.Languages]["Linearmeter"]}</option> */}
                        {dimensions.map(dimensions=>(
                            <option value={dimensions.dimension}>{details.Languages == "en" ?dimensions.dimension: dimensions.dimension_arabic}</option>
                        ))
                        }
                    </select>
            </div>
        </div>
        <div className="row">
            {!loader ?
            kitchenShapes.map(shape =>(

            <div className="col-lg-6 col-md-12 col-sm-12" onClick={()=> selectShape(shape.title, shape.title_arabic, shape._id)}> 
                {shapeSelected == shape.title?
                <div className={details.Languages == "en"?"tick-div-class": "tick-div-class tick-div-class-arabic"}>
                    <img src={Tick} alt="tick" />
                </div>
                :""
                }
                <div className="kitchen-shape" style={{padding: shape.title === "Straight Kitchen" ? "0% 0px": ""}}>
                    <img data-src={shape.kitchen_shape_image} alt={shape.kitchen_shape_image} className="lazyload shape-image-div"/>
                </div>
                <div className="kitchen-shape-title">
                    {details.Languages == "en" ? shape.title : shape.title_arabic}
                </div>
            </div>
            ))

            :
            <div className="loader_center">
                <ClipLoader color={'#242E49'} loading={loader} />
            </div>
            }
            <div className="row continue-button-wrap">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <input class="continue-button"
                    type="button"
                    onClick={continueButtonClick}
                    // disabled={sliderValue == 0 || shapeSelected == ""? true : false}
                    value={Translation[details.Languages]["ContinueButton"]}/>
                </div>
                {mandatoryError?
                <div className="mandatory-error">
                    All fields are mandatory
                </div>
                :""
                }
            </div>
        </div>
    </div>
</React.Fragment>
);
}

export default DesignKitchen;