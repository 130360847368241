import axios from "axios";
import config from "../config/properties";


const KITCHEN_SUCCESS = 'KITCHEN_SUCCESS';
const KITCHEN__FAILURE = 'KITCHEN__FAILURE';


export function kitchenlisting(values)
{
    return async (dispatch) => {
    dispatch(kitchenRequest('Request'))
        function onSuccess(success)
        {
            dispatch({type:KITCHEN_SUCCESS,success})
            return success
        }
    
        function onError(error)
        {
            dispatch({type:KITCHEN__FAILURE,error})
            return error	
        }
        
        try{
            const success = await axios.post(config.base_url+'customer/getAllKitchenDesignsCustomerWeb',values,
            {headers: {
                'Content-Type': 'application/json',
        }}
            
            )
            console.log(success)
            return onSuccess(success);
    }
        catch(error)
        {
            return onError(error);
        }
    
    }
    }


    export function kitchendetailsvalue(data) {
        try {
          
          return async () => {
      
            return await axios.post(config.base_url + "customer/getDesignDetails",data
              
            )
          }
      
        }
        catch (e) {
      
        }
      }


    const kitchenRequest=(response)=>({
      type:"KITCHEN_REQUESTING",
      response
    })


