import React from 'react';
import Router from './Router';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"

function App() {

  return (
      <Router />
    
  )
}

export default App;
