import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../images/logo_english.svg";
import LogoArabic from "../../images/logo_arabic.svg";
import NotificationBell from "../../images/notification.png";
import Notificationchat from "../../images/chat-msg.png";
import ProfileImage from "../../images/user-profile.png";
import { Link } from "react-router-dom";
import "./index.css";
import Translation from "../../language/translate";
import { headermenuoptions } from "../../actions/headermenuoptionactions";
import { kitchenlisting } from "../../actions/kitchenlistingactions";
import {
  getallcustomerorderslist,
  getAllListingDetailsForHome,
} from "../../actions/orderlistingactions";
import { logoutApp } from "../../actions/loginactions";
import {
  languagechangestatus,
  getchatnotificationcount,
} from "../../actions/notification";
import UpdateUserDetails from "../update-user-details";
import DrawerComponent from "../../components/notification-and-order-list-drawer";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const details = useSelector((state) => state);
  const location=useLocation();
  const localStorageLng = "ar";
  const [click, setClick] = useState(false);
  const [language, setLanguage] = useState("ar");
  const [mobileDevice, setMobileDevice] = useState(false);
  const [kitchenDropdown, setKitchenDropdown] = useState(false);
  const [kitchenDropdownmob, setKitchenDropdownmob] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [mobileProfileDropdown, setMobileProfileDropdown] = useState(false);
  const [dropdownClick, setDropdownClick] = useState(false);
  const handleDropdownClick = () => setDropdownClick(!click);
  const toggleMobileMenu = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerPage, setDrawerPage] = useState("");
  const [detailsUpdatePopOpen, setDetailsUpdatePopOpen] = useState(false);
  const [editScreen, setEditScreen] = useState(true);
  const [customerorderlist, setcustomerorderlist] = useState([]);
  const [showLoader, setcustomerorderloader] = useState(true);
  const [notificationcount, setnotificationcount] = useState();
  const [chatnotificationcount, setchatnotificationcount] = useState();
  const [allheaderlist, setheadervalues] = useState({
    menudropdownptions: [],
  });

  useEffect(() => {
    //language=="en"?localStorage.setItem('language','en'):localStorage.setItem('language','ar')
    //localStorage.getItem("language") == "ar" ? localStorage.setItem('fromheader','') :localStorage.setItem("fromheader", "head")
    localStorage.getItem("language") == "en" &&
    localStorage.getItem("fromheader") == "head"
      ? localStorage.setItem("language", "en")
      : localStorage.setItem("language", "ar");
    localStorage.getItem("language") == "ar"
      ? document.getElementsByTagName("body")[0].setAttribute("dir", "rtl")
      : document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    if (localStorage.getItem("user_registered") == "true") {
      listingDetailsForHome();
    }
  }, []);

  const listingDetailsForHome = async () => {
    let obj = {
      location: {
        type: "Point",
        coordinates: [
          parseFloat(localStorage.getItem("longitude")),
          parseFloat(localStorage.getItem("latitude")),
        ],
      },
      platform: "web",
      token: localStorage.getItem("user_token"),
      city: localStorage.getItem("user_city"),
      customer_id: localStorage.getItem("customer_id"),
    };
    const listHomeResponse = await dispatch(getAllListingDetailsForHome(obj));
    console.log(listHomeResponse);
    if (listHomeResponse.data.status_code == 200) {
      listHomeResponse.data.language == "ar"
        ? document.getElementsByTagName("body")[0].setAttribute("dir", "rtl")
        : document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
      localStorage.setItem("language", listHomeResponse.data.language);
      setLanguage(listHomeResponse.data.language);
      dispatch({
        type: "CHANGE_LANGUAGE",
        language: listHomeResponse.data.language,
      });
    }
  };
  useEffect(() => {
    if (localStorage.getItem("language") == "ar") {
      setLanguage("ar");
    } else {
      setLanguage("en");
    }
    dispatch({
      type: "CHANGE_LANGUAGE",
      language: localStorage.getItem("language"),
    });
    window.scrollTo(0, 0);
    onLoadheadervalues();
    switchcountvalue();
    gumLetFunction();
    locationload();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 960) {
      setMobileDevice(true);
    } else {
      setMobileDevice(false);
    }
    const setDeviceChange = () => {
      if (window.innerWidth < 960) {
        setMobileDevice(true);
      } else {
        setMobileDevice(false);
      }
    };
    window.addEventListener("resize", setDeviceChange);
  }, []);

  const hideshowdiv = () => {
    var slidesec = document.getElementById("myDIV");
    if (slidesec.style.display === "block") {
      slidesec.style.display = "none";
    } else {
      slidesec.style.display = "block";
    }
  };

  const switchcountvalue = async () => {
    let obj = {
      customer_id: localStorage.getItem("customer_id"),
    };
    if (localStorage.getItem("user_registered") != undefined) {
      const Getcount = await dispatch(getchatnotificationcount(obj));
      console.log(Getcount);
      if (Getcount.data.status_code == 200) {
        setnotificationcount(Getcount.data.total_unread_notifications);
        setchatnotificationcount(Getcount.data.total_unread_messages);
        if (Getcount.data.total_unread_notifications == 0) {
          localStorage.setItem("setnotificationcount", "false");
        }
      }
    }
  };

  const switchLanguage = async (lang) => {
    lang == "ar"
      ? localStorage.setItem("fromheader", "")
      : localStorage.setItem("fromheader", "head");
    lang == "ar"
      ? document.getElementsByTagName("body")[0].setAttribute("dir", "rtl")
      : document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
    localStorage.setItem("language", lang);
    setLanguage(lang);
    dispatch({
      type: "CHANGE_LANGUAGE",
      language: lang,
    });
    if (localStorage.getItem("user_registered") == "true") {
      let obj = {
        id: localStorage.getItem("customer_id"),
        app_language: localStorage.getItem("language"),
        token: localStorage.getItem("user_token"),
      };
      const LanguagechangeRequest = await dispatch(languagechangestatus(obj));
      console.log(LanguagechangeRequest);
    }
  };

  const enableKitchenDropdown = () => {
    if (mobileDevice == false) {
      setKitchenDropdown(true);
    }
  };

  const enableKitchenDropdownmobile = () => {
    if (mobileDevice == true) {
      setKitchenDropdownmob(true);
    }
  };

  const disableKitchenDropdown = () => {
    if (mobileDevice == false) {
      setKitchenDropdown(false);
    }
  };

  const disableKitchenDropdownmobile = () => {
    if (mobileDevice == false) {
      setKitchenDropdownmob(false);
    }
  };

  const enableProfileDropdown = () => {
    if (mobileDevice == false) {
      setProfileDropdown(true);
    }
  };

  const disableProfileDropdown = () => {
    if (mobileDevice == false) {
      setProfileDropdown(false);
    }
  };

  const toggleMobileProfileDropdown = () => {
    setMobileProfileDropdown(!mobileProfileDropdown);
  };

  const onLoadheadervalues = async () => {
    const headermenulistingvalues = await dispatch(headermenuoptions());
    if (headermenulistingvalues.data.status_code == 200) {
      setheadervalues({
        menudropdownptions: headermenulistingvalues.data.list,
      });
    }
  };

  const gumLetFunction = () => {
    const script = document.createElement("script");
    script.src = "https://cdn.gumlet.com/gumlet.js/2.0/gumlet.min.js";
    script.async = true;
    script.onload = () => scriptLoaded();
    document.body.appendChild(script);
  };

  const scriptLoaded = () => {
    window.GUMLET_CONFIG = {
      hosts: [
        {
          current: "https://niuli-images.s3.ap-south-1.amazonaws.com",
          gumlet: "niuli-images.gumlet.io",
        },
        {
          current: "https://s3.ap-south-1.amazonaws.com",
          gumlet: "niuli-images.gumlet.io",
        },
      ],
      lazy_load: true,
    };
    window.gumlet.init(window.GUMLET_CONFIG);
  };

  const locationload = () => {
    const script = document.createElement("script");
    script.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBMJPnpsiyIhXFZJztASrPPSxjZzeRMnTw&libraries=places&language=" +
      localStorage.getItem("language");
    script.async = true;
    document.body.appendChild(script);
  };

  const logout = async () => {
    let obj = {
      customer_id: localStorage.getItem("customer_id"),
      token: localStorage.getItem("user_token"),
    };
    const logoutResponse = await dispatch(logoutApp(obj));
    if (logoutResponse.data.status_code == 200) {
      localStorage.clear();
      history.push({ pathname: "/" });
      window.location.reload();
    }
  };

  const openNotificationDrawer = () => {
    setDrawerOpen(true);
    setDrawerPage("notification");
  };

  const openchatNotificationDrawer = () => {
    setDrawerOpen(true);
    setDrawerPage("chatnotifications");
  };

  const openMyorderDrawer = async () => {
    setDrawerOpen(true);
    setDrawerPage("viewOrders");
    let ordervalues = {
      customer_id: localStorage.getItem("customer_id"),
      token: localStorage.getItem("user_token"),
    };
    const orderlist = await dispatch(getallcustomerorderslist(ordervalues));
    if (orderlist.data.status_code == 200) {
      setcustomerorderlist(orderlist.data.order_list);
      setcustomerorderloader(false);
    } else if (
      orderlist.data.status_code == 408 ||
      orderlist.data.status_code == 402
    ) {
      let obj = {
        customer_id: localStorage.getItem("customer_id"),
        deviceType: "web",
      };
      const logoutResponse = await dispatch(logoutApp(obj));
      if (logoutResponse.data.status_code == 200) {
        localStorage.clear();
        history.push({ pathname: "/" });
        window.location.reload();
      }
    } else {
      history.push({ pathname: "/login" });
    }
  };

  const openMylocationDrawer = () => {
    setDrawerOpen(true);
    setDrawerPage("viewLocation");
  };

  const detailsUpdateModal = (page) => {
    setEditScreen(page);
    setDetailsUpdatePopOpen(true);
  };

  const closeUpdateProfileModal = () => {
    setDetailsUpdatePopOpen(false);
  };
  const closedrawer = () => {
    setDrawerOpen(false);
    setDrawerPage("");
  };
  const loginUser = () => {
    history.push({ pathname: "/login" });
  };

  const fetchlisting = async (value, name) => {
    if (value != undefined) {
      let kitchenallvalues = {
        size: 9,
        page: 0,
        category: value,
        token: localStorage.getItem("user_token"),
      };
      const kitchenlistingvalues = await dispatch(
        kitchenlisting(kitchenallvalues)
      );
      history.push({ pathname: "/kitchenlisting/" + value + "/" + name });
      localStorage.setItem("testing", "true");
    }
  };

  if(location.pathname==="/campaign"){
    return <></>
  }

  return (
    <>
      {/* NAVBAR_MAIN */}
      <nav className={"niuli-navbar"}>
        <div
          className={
            "niuli-navbar-box" +
            " " +
            (language == "en"
              ? "niuli-navbar-box-arabic"
              : "niuli-navbar-box-english")
              // ? "niuli-navbar-box-english"
              // : "niuli-navbar-box-arabic")
          }
        >
          <div
            className={
              "niuli-navbar-main" +
              " " +
              (mobileDevice
                ? "niuli-navbar-main-mobile"
                : "niuli-navbar-main-pc") +
              " " +
              (language == "en"
                ? "niuli-navbar-main-arabic"
                : "niuli-navbar-main-english")
                // ? "niuli-navbar-main-english"
                // : "niuli-navbar-main-arabic")
            }
          >
            <Link
              to={localStorage.getItem("user_token") ? "/home" : "/"}
              className={
                mobileDevice
                  ? "niuli-navbar-main-logo-mobile"
                  : "niuli-navbar-main-logo-pc"
              }
            >
              {/* <img className="logo_appnew" src={language == "en"?Logo:LogoArabic} alt="Niuli-logo" /> */}
              <img className="logo_appnew" src={Logo} alt="Niuli-logo" />
            </Link>
            {localStorage.getItem("user_registered") == "true" ? (
              <div className="niuli-navbar-loc" onClick={openMylocationDrawer}>
                <div
                  className={
                    "niuli-navbar-loc-city" +
                    " " +
                    (mobileDevice
                      ? "niuli-navbar-loc-city-mobile font-face-gmregular"
                      : "niuli-navbar-loc-city-pc font-face-gm")
                  }
                >
                  {localStorage.getItem("user_city")}
                </div>
                <div
                  className={
                    "niuli-navbar-loc-address" +
                    " " +
                    (mobileDevice
                      ? "niuli-navbar-loc-address-mobile font-face-gmregular"
                      : "niuli-navbar-loc-address-pc font-face-gmregular")
                  }
                  title={localStorage.getItem("address")}
                >
                  {localStorage.getItem("address")}
                </div>
                <div className="niuli-navbar-loc-change-arrow">
                  <i
                    class="fa fa-angle-down niuli-navbar-down-arrow"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
            ) : (
              <div className="niuli-navbar-loc">
                <div
                  className={
                    "niuli-navbar-loc-city" +
                    " " +
                    (mobileDevice
                      ? "niuli-navbar-loc-city-mobile font-face-gmregular"
                      : "niuli-navbar-loc-city-pc font-face-gmregular")
                  }
                >
                  {localStorage.getItem("user_city")}
                </div>
              </div>
            )}
          </div>

          {/* NAVBAR_MENU */}
          <ul
            className={
              click ? "niuli-nav-menu active head_new" : "niuli-nav-menu"
            }
          >
            {/* <li className='niuli-nav-item'>
            <Link to='/kitchenlisting/5f3168c79c9fe2dd0bc3dba1/Modern%20Classic%20Looks' className='niuli-nav-links' onClick={closeMobileMenu}>
              Appliances
            </Link>
              </li> */}

            <li className="niuli-nav-item" onClick={closeMobileMenu}>
              <Link
                to="/getyourkitchen"
                class={
                  details.Languages == "en"
                    ? "niuli-nav-links link_nav_def font-face-gmregular"
                    : "niuli-nav-links link_nav_def font-face-gmregular remv_space"
                }
              >
                {Translation[details.Languages]["GetyourKitchen"]}
              </Link>
            </li>

            <li className="niuli-nav-item" onClick={closeMobileMenu}>
              <Link
                class={
                  details.Languages == "en"
                    ? "niuli-nav-links link_nav_def font-face-gmregular"
                    : "niuli-nav-links link_nav_def font-face-gmregular remv_space"
                }
                to="/yourkitchenlasts"
              >
                {Translation[details.Languages]["yourKitchenlasts"]}
              </Link>
            </li>

            <li
              className="niuli-nav-item heade_Deskhide"
              onClick={closeMobileMenu}
            >
              <Link
                class={
                  details.Languages == "en"
                    ? "niuli-nav-links link_nav_def font-face-gmregular"
                    : "niuli-nav-links link_nav_def font-face-gmregular remv_space"
                }
                to="/ourkitchen"
              >
                {Translation[details.Languages]["Kitchen"]}
              </Link>
            </li>

            {localStorage.getItem("user_token") == "" ||
            localStorage.getItem("user_token") == undefined ? (
              <li>
                <Link
                  to="/login"
                  className="niuli-nav-links-mobile"
                  onClick={closeMobileMenu}
                >
                  {Translation[details.Languages]["login"]}
                </Link>
              </li>
            ) : (
              ""
            )}

            <li
              className="niuli-nav-item hidedes"
              onMouseEnter={enableKitchenDropdown}
              onMouseLeave={disableKitchenDropdown}
            >
              <Link
                to="/ourkitchen"
                className={
                  details.Languages == "en"
                    ? "niuli-nav-links link_nav_def font-face-gmregular"
                    : "niuli-nav-links link_nav_def font-face-gmregular remv_space"
                }
                onClick={closeMobileMenu}
              >
                {Translation[details.Languages]["Kitchen"]}
                {/* <i className={"fa fa-angle-down" + " " + (mobileDevice ? 'niuli-navbar-dropdown-arrow-mobile' : 'niuli-navbar-dropdown-arrow-pc')} /> */}
              </Link>
              {/* {kitchenDropdown && <ul
          onClick={handleDropdownClick}
          className={click ? 'niuli-dropdown-menu clicked ' : 'niuli-dropdown-menu'}>
                  {
									allheaderlist.menudropdownptions !=undefined?
									allheaderlist.menudropdownptions.map((allList)=>{
									return(
                    <li className={"niuli-dropdown-li" + " " + (language == 'en' ? 'niuli-dropdown-li-english' : 'niuli-dropdown-li-arabic')} onClick={() => { setDropdownClick(false);fetchlisting(allList._id, allList.category_name) }}><a>{details.Languages == "en" ?allList.category_name: allList.category_name_arabic}</a>
										</li>)}):""}</ul>} */}
            </li>

            {localStorage.getItem("user_registered") == "true" ? (
              <li className="niuli-nav-item">
                <Link
                  className={
                    details.Languages == "en"
                      ? "niuli-nav-links link_nav_def font-face-gmregular"
                      : "niuli-nav-links link_nav_def font-face-gmregular remv_space"
                  }
                  onClick={() => {
                    closeMobileMenu();
                    openMyorderDrawer();
                  }}
                >
                  {Translation[details.Languages]["MyOrders"]}
                </Link>
              </li>
            ) : (
              ""
            )}

            {/* <li className='niuli-nav-item hidemob'  onMouseEnter={enableKitchenDropdownmobile} onMouseLeave={disableKitchenDropdownmobile}>
            <span to='/'
              className='niuli-nav-links link_nav_def font-face-gmregular'
              onClick={hideshowdiv}
              >
                  {Translation[details.Languages]["Kitchen"]}
                  <i className={"fa fa-angle-down" + " " + (mobileDevice ? 'fix_downarrw' : 'niuli-navbar-dropdown-arrow-pc')} />
              </span>
            {kitchenDropdownmob && <ul
          onClick={handleDropdownClick}
          className={localStorage.getItem('user_registered') == "true" ? 'niuli-dropdown-menunew':'niuli-dropdown-menunewqr'} id="myDIV">
                  {
									allheaderlist.menudropdownptions !=undefined?
									allheaderlist.menudropdownptions.map((allList)=>{
									return(
                    <li className={"niuli-dropdown-li" + " " + (language == 'en' ? 'niuli-dropdown-li-english' : 'niuli-dropdown-li-arabic')} onClick={() => { closeMobileMenu();setDropdownClick(false);fetchlisting(allList._id, allList.category_name) }}><a>{details.Languages == "en" ?allList.category_name: allList.category_name_arabic}</a>
										</li>)}):""}</ul>}</li> */}

            {localStorage.getItem("user_token") == "" ||
            localStorage.getItem("user_token") == undefined ? (
              <li className="niuli-nav-item not-in-mobile-class font-face-gmregular">
                <Link
                  to="/login"
                  className="niuli-login-btn"
                  onClick={closeMobileMenu}
                >
                  {Translation[details.Languages]["login"]}
                </Link>
              </li>
            ) : (
              ""
            )}

            {localStorage.getItem("user_registered") == "true" ? (
              <>
                <li
                  className="niuli-nav-item not-in-mobile-class font-face-gmregular"
                  onMouseEnter={enableProfileDropdown}
                  onMouseLeave={disableProfileDropdown}
                >
                  <Link
                    to="/"
                    className={
                      details.Languages == "en"
                        // ? "niuli-nav-links sat_noti"
                        // : "niuli-nav-links sat_notiarb remv_space"
                        ? "niuli-nav-links sat_notiarb remv_space"
                        : "niuli-nav-links sat_noti"
                    }
                    onClick={closeMobileMenu}
                  >
                    <img
                      src={ProfileImage}
                      className="niuli-navbar-profile-icon"
                      alt="Niuli-logo"
                    />
                  </Link>
                  {profileDropdown && (
                    <ul
                      onClick={handleDropdownClick}
                      className={
                        click
                          ? "niuli-dropdown-menu clicked"
                          : "niuli-dropdown-menu"
                      }
                    >
                      <li>
                        <Link
                          className={
                            "niuli-dropdown-li" +
                            " " +
                            (language == "en"
                              // ? "niuli-dropdown-li-english font-face-gmregular"
                              // : "niuli-dropdown-li-arabic font-face-gmregular")
                              ? "niuli-dropdown-li-arabic font-face-gmregular"
                              : "niuli-dropdown-li-english font-face-gmregular")
                          }
                          onClick={() => {
                            setDropdownClick(false);
                            detailsUpdateModal("profile");
                          }}
                        >
                          {Translation[details.Languages]["EditProfile"]}
                        </Link>
                      </li>

                      <li>
                        <Link
                          className={
                            "niuli-dropdown-li" +
                            " " +
                            (language == "en"
                              // ? "niuli-dropdown-li-english font-face-gmregular"
                              // : "niuli-dropdown-li-arabic font-face-gmregular")
                              ? "niuli-dropdown-li-arabic font-face-gmregular"
                              : "niuli-dropdown-li-english font-face-gmregular")
                          }
                          onClick={() => {
                            setDropdownClick(false);
                            detailsUpdateModal("phoneNumber");
                          }}
                        >
                          {Translation[details.Languages]["ChangePhoneNumber"]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            "niuli-dropdown-li" +
                            " " +
                            (language == "en"
                              // ? "niuli-dropdown-li-english font-face-gmregular"
                              // : "niuli-dropdown-li-arabic font-face-gmregular")
                              ? "niuli-dropdown-li-arabic font-face-gmregular"
                              : "niuli-dropdown-li-english font-face-gmregular")
                          }
                          to="/mytransactions"
                          onClick={() => {
                            setDropdownClick(false);
                          }}
                        >
                          {Translation[details.Languages]["Mytransactions"]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            "niuli-dropdown-li" +
                            " " +
                            (language == "en"
                              // ? "niuli-dropdown-li-english font-face-gmregular"
                              // : "niuli-dropdown-li-arabic font-face-gmregular")
                              ? "niuli-dropdown-li-arabic font-face-gmregular"
                              : "niuli-dropdown-li-english font-face-gmregular")
                          }
                          to="/service-tickets"
                          onClick={() => {
                            setDropdownClick(false);
                          }}
                        >
                          {Translation[details.Languages]["serviceTickets"]}
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={
                            "niuli-dropdown-li" +
                            " " +
                            (language == "en"
                              // ? "niuli-dropdown-li-english font-face-gmregular"
                              // : "niuli-dropdown-li-arabic font-face-gmregular")
                              ? "niuli-dropdown-li-arabic font-face-gmregular" 
                              : "niuli-dropdown-li-english font-face-gmregular")
                          }
                          to="/"
                          onClick={() => {
                            setDropdownClick(false);
                            logout();
                          }}
                        >
                          {Translation[details.Languages]["Logout"]}
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </>
            ) : (
              ""
            )}

            {localStorage.getItem("user_registered") == "true" ? (
              <li className="niuli-nav-item not-in-mobile-class font-face-gmregular">
                <span
                  className={
                    details.Languages == "en"
                      ? "niuli-nav-links sat_noti cur_ap"
                      : "niuli-nav-links sat_notiarb cur_ap remv_space"
                  }
                  onClick={closeMobileMenu}
                >
                  <img
                    src={NotificationBell}
                    alt="Niuli-logo"
                    onClick={() => openNotificationDrawer()}
                  />
                  {notificationcount >= 1 ||
                  localStorage.getItem("setnotificationcount") == "true" ? (
                    <span className="fix_circleredDot">
                      <i
                        className={
                          details.Languages == "en"
                            ? "fa fa-circle notcir"
                            : "fa fa-circle notcirar"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </li>
            ) : (
              ""
            )}

            {localStorage.getItem("user_registered") == "true" ? (
              <li className="niuli-nav-item not-in-mobile-class font-face-gmregular">
                <span
                  className={
                    details.Languages == "en"
                      ? "niuli-nav-links sat_noti cur_ap"
                      : "niuli-nav-links sat_notiarb cur_ap remv_space"
                  }
                  onClick={closeMobileMenu}
                >
                  <img
                    className="chat_imagenot"
                    src={Notificationchat}
                    alt="Niuli-logo"
                    onClick={() => openchatNotificationDrawer()}
                  />
                  {chatnotificationcount >= 1 ? (
                    <span className="fix_circleredDot">
                      <i
                        className={
                          details.Languages == "en"
                            ? "fa fa-circle notcir"
                            : "fa fa-circle notcirar"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </li>
            ) : (
              ""
            )}

            <li className="niuli-nav-item not-in-mobile-class font-face-gmregular">
              <Link
                className="niuli-navbar-language-switch"
                onClick={() =>
                  switchLanguage(
                    localStorage.getItem("language")
                      ? localStorage.getItem("language") == "en"
                        ? "ar"
                        : "en"
                      : "ar"
                  )
                }
              >
                {localStorage.getItem("language")
                  ? localStorage.getItem("language") == "en"
                    ? "AR"
                    : "EN"
                  : "AR"}
              </Link>
            </li>
          </ul>

          {/* NAVBAR_END_STARTS */}
          <div
            className={
              "niuli-navbar-end" +
              " " +
              (mobileDevice
                ? "niuli-navbar-end-mobile "
                : "niuli-navbar-end-pc niuli-nav-display-none") +
              " " +
              (language == "en"
                // ? "niuli-navbar-end-english"
                // : "niuli-navbar-end-arabic")
                ? "niuli-navbar-end-arabic"
                : "niuli-navbar-end-english")
            }
          >
            {localStorage.getItem("user_registered") == "true" ? (
              <>
                <div className="niuli-navbar-end-item">
                  <img
                    src={ProfileImage}
                    className="niuli-navbar-profile-icon"
                    alt="Niuli-logo"
                    onClick={toggleMobileProfileDropdown}
                  />
                  {/* MOBILE_DROPDOWN */}
                  <ul
                    className={
                      mobileProfileDropdown
                        ? "niuli-dropdown-menu niuli-dropdown-mobile"
                        : "niuli-dropdown-menu clicked niuli-dropdown-mobile"
                    }
                  >
                    <li>
                      <Link
                        className={
                          "niuli-dropdown-li" +
                          " " +
                          (language == "en"
                            // ? "niuli-dropdown-li-english font-face-gmregular"
                            // : "niuli-dropdown-li-arabic font-face-gmregular")
                            ? "niuli-dropdown-li-arabic font-face-gmregular"
                            : "niuli-dropdown-li-english font-face-gmregular")
                        }
                        onClick={() => {
                          toggleMobileProfileDropdown();
                          detailsUpdateModal("profile");
                        }}
                      >
                        {Translation[details.Languages]["EditProfile"]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          "niuli-dropdown-li" +
                          " " +
                          (language == "en"
                            // ? "niuli-dropdown-li-english font-face-gmregular"
                            // : "niuli-dropdown-li-arabic font-face-gmregular")
                            ? "niuli-dropdown-li-arabic font-face-gmregular"
                            : "niuli-dropdown-li-english font-face-gmregular")
                        }
                        // onClick={toggleMobileProfileDropdown}
                        onClick={() => {
                          toggleMobileProfileDropdown();
                          detailsUpdateModal("phoneNumber");
                        }}
                      >
                        {Translation[details.Languages]["ChangePhoneNumber"]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          "niuli-dropdown-li" +
                          " " +
                          (language == "en"
                            // ? "niuli-dropdown-li-english font-face-gmregular"
                            // : "niuli-dropdown-li-arabic font-face-gmregular")
                            ? "niuli-dropdown-li-arabic font-face-gmregular"
                            : "niuli-dropdown-li-english font-face-gmregular")
                        }
                        to="/mytransactions"
                        onClick={toggleMobileProfileDropdown}
                      >
                        {Translation[details.Languages]["Mytransactions"]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          "niuli-dropdown-li" +
                          " " +
                          (language == "en"
                            // ? "niuli-dropdown-li-english font-face-gmregular"
                            // : "niuli-dropdown-li-arabic font-face-gmregular")
                            ? "niuli-dropdown-li-arabic font-face-gmregular"
                            : "niuli-dropdown-li-english font-face-gmregular")
                        }
                        to="/service-tickets"
                        onClick={toggleMobileProfileDropdown}
                      >
                        {Translation[details.Languages]["serviceTickets"]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          "niuli-dropdown-li" +
                          " " +
                          (language == "en"
                            // ? "niuli-dropdown-li-english font-face-gmregular"
                            // : "niuli-dropdown-li-arabic font-face-gmregular")
                            ? "niuli-dropdown-li-arabic font-face-gmregular"
                            : "niuli-dropdown-li-english font-face-gmregular")
                        }
                        onClick={() => {
                          toggleMobileProfileDropdown();
                          logout();
                        }}
                      >
                        {Translation[details.Languages]["Logout"]}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={
                          "niuli-dropdown-li" +
                          " " +
                          (language == "en"
                            // ? "niuli-dropdown-li-english font-face-gmregular"
                            // : "niuli-dropdown-li-arabic font-face-gmregular")
                            ? "niuli-dropdown-li-arabic font-face-gmregular"
                            : "niuli-dropdown-li-english font-face-gmregular")
                        }
                        onClick={toggleMobileProfileDropdown}
                      >
                        {Translation[details.Languages]["Closedrp"]}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="niuli-navbar-end-item">
                  <img
                    src={NotificationBell}
                    alt="Niuli-logo"
                    onClick={() => openNotificationDrawer()}
                  />
                  {notificationcount >= 1 ||
                  localStorage.getItem("setnotificationcount") == "true" ? (
                    <span className="fix_circleredDot">
                      <i
                        className={
                          details.Languages == "en"
                            ? "fa fa-circle notcirmob"
                            : "fa fa-circle notcirarmob"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="niuli-navbar-end-item">
                  <img
                    className="chat_imagenot"
                    src={Notificationchat}
                    alt="Niuli-logo"
                    onClick={() => openchatNotificationDrawer()}
                  />
                  {chatnotificationcount >= 1 ? (
                    <span className="fix_circleredDot">
                      <i
                        className={
                          details.Languages == "en"
                            ? "fa fa-circle notcirmob"
                            : "fa fa-circle notcirarmob"
                        }
                        aria-hidden="true"
                      ></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="niuli-navbar-end-item">
                <img
                  src={ProfileImage}
                  className="niuli-navbar-profile-icon"
                  alt="Niuli-logo"
                  onClick={loginUser}
                />
              </div>
            )}
            <div className="niuli-navbar-end-item">
              <div
                className="niuli-navbar-language-switch"
                onClick={() =>
                  switchLanguage(
                    localStorage.getItem("language")
                      ? localStorage.getItem("language") == "en"
                        ? "ar"
                        : "en"
                      : "ar"
                  )
                }
              >
                {localStorage.getItem("language")
                  ? localStorage.getItem("language") == "en"
                    ? "AR"
                    : "EN"
                  : "AR"}
              </div>
            </div>
            <div
              className={
                "niuli-navbar-end-item" +
                " " +
                (mobileDevice
                  ? "niuli-navbar-menu-icon-mobile-div"
                  : "niuli-navbar-menu-icon-pc-div")
              }
              onClick={toggleMobileMenu}
            >
              <i
                className={
                  "niuli-navbar-menu-icon" +
                  " " +
                  (click
                    ? "fa fa-times niuli-navbar-contrast-color"
                    : "fa fa-bars niuli-navbar-contrast-color")
                }
              ></i>
            </div>
          </div>
          {/* NAVBAR_END_ENDS */}
        </div>
        <DrawerComponent
          drawerOpen={drawerOpen}
          closedrawer={() => closedrawer}
          drawerPage={drawerPage}
          customeroderlist={customerorderlist}
          showLoader={showLoader}
        />
        <UpdateUserDetails
          profileDetailsUpdatePopOpen={detailsUpdatePopOpen}
          editScreen={editScreen}
          closeUpdateProfileModal={closeUpdateProfileModal}
        />
      </nav>
    </>
  );
}

export default Navbar;
