import React, {useState, useLayoutEffect, useEffect} from 'react';
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import LetsBegin from "../../components/consultation-lets-begin"
import Translation from "../../language/translate"
import ChooseKitchenStyle from "../../components/consultation-choose-kitchen-style"
import DesignKitchen from "../../components/consultation-design-kitchen"
import Meetdesignerswithslot from "../../components/meetdesigners-withslot"
import Confirmation from "../../components/consultation-confirmation"
import MoreInformation from "../../components/consultation-more-information"
import ReadyToMeet from "../../components/consultation-ready-to-meet"
import ConsultationMeetLocation from "../../components/consultation-meet-location"
import CloseIcon from "../../images/close-icon.png"
import NonKitchenInfo from "../../components/non-kitchen-info"
import {designKitchen, chooseKitchenStyleFun, moreInformation} from "../../actions/bookFreeConsultationActions"
import "antd/dist/antd.css";
import "./index.css"

const BookFreeConsultation=(props)=>{
const dispatch = useDispatch();
const [modalContent, setModalContent]=useState(0)
const [modalNonKitchenContent, setModalNonKitchenContent]=useState(0)
const [meetdesignerModal, setmeetdesignerModal]=useState(false)
const closemeetdesigner =()=> setmeetdesignerModal(false)
const [noDesigner, setNoDesigner] = useState("")
const [dataOrder, setDataOrder] = useState("")
const [kitchenbook, setKitchen] = useState(false)
const [nonkitchenbook, setNonKitchen] = useState(false)
const details = useSelector(state => state);

useLayoutEffect(() => {
    if (props.bookModalOpen === true) {
      document.body.style.position = 'fixed';
    }
    else{
        document.body.style.position = 'unset';
    }
    // return () => {
    //   document.body.style.position = 'unset';
    // };
  }, [props.bookModalOpen]);


const handleClose = () => {
setModalContent(0)
setModalNonKitchenContent(0)
setKitchen(false)
setNonKitchen(false)
props.closeBookModal();
dispatch({
    type: "KITCHEN_SIZE_SHAPE",
    data: {},
    })
dispatch({
    type: "CHOOSE_KITCHEN_STYLE",
    data: {},
    })
dispatch({
    type: "DEMO_MORE_INFORMATION",
    data: {},
    })
dispatch({
    type: "MEET_DATE_TIME",
    data: {},
    })
dispatch({
    type: "BOOK_A_DEMO_RESPONSE",
    data: {},
})
dispatch({
    type: "NON_KITCHEN_DATA",
    data: {},
})
}

const handleNext = (data) => {
setKitchen(true)
setNoDesigner(data)
setModalContent(modalContent + 1)
}

const handleNonKitchenNext = (data) => {
    setNonKitchen(true)
    setNoDesigner(data)
    setModalNonKitchenContent(modalNonKitchenContent + 1)
}

const handlePrevious = () => {
setModalContent(modalContent - 1)
}

const handleNonKitchenPrevious = () => {
    setModalNonKitchenContent(modalNonKitchenContent - 1)
    }

const handlePreviousReadyToMeet = () => {
    setModalContent(1)
    }

    const handlePreviousReadyToMeetNonKitchen = () => {
        setModalNonKitchenContent(1)
        }

const onClickGotIt = () => { 
// setmeetdesignerModal(true)
    if(props.repeatOrder == false) { 
        props.orderList();
        handleClose("")
    } else {
        handleClose("")
    }
}

useEffect(() => {
    if(props.repeatOrder === true){
        // setModalContent(4)
        setModalContent(1)
        setDataOrder(props.orderDetailsData)
    } else setModalContent(0)
}, [props.repeatOrder])

useEffect(()=>{
    if(props.bookModalOpen===true && localStorage.getItem("campaignUser")){
        handleNext()
        // localStorage.removeItem("campaignUser")
    }
},[props.bookModalOpen])


const designKitchen = (aa) => {
    console.log(aa)
}

return (
<React.Fragment>
    <div id="book-consultation-wrap">
        <Modal centered visible={props.bookModalOpen} onCancel={handleClose} footer={null} maskClosable={false}
            destroyOnClose={true} backdrop={"static"} 
            className={nonkitchenbook? modalNonKitchenContent===0 ?"lets-started": modalNonKitchenContent===6 || modalNonKitchenContent===7? "confirmation-modal":"bookModal" : modalContent===0 ?"lets-started": modalContent===6 || modalContent===7? "confirmation-modal":"bookModal"}
            closeIcon={<img src={CloseIcon} alt="" />}
        >

        {/* <ConsultationMeetLocation handlePrevious={handlePrevious} handleNext={handleNext}/> */}
        {nonkitchenbook? 
        modalNonKitchenContent !== 0 && modalNonKitchenContent !== 7 && modalNonKitchenContent !== 6?
        <div className={modalNonKitchenContent===1 ? "current-step-one" : "current-step" } style={modalNonKitchenContent===1 ?{
            bottom: "155px" } : {bottom: "118px" }}>
            {Translation[details.Languages]["Steps"]} {modalNonKitchenContent}/3
        </div>
        :""
        :
        modalContent !== 0 && modalContent !== 7 && modalContent !== 6?
        <div className={modalContent===1 ? "current-step-one" : "current-step" } style={modalContent===1 ?{
            bottom: "155px" } : {bottom: "118px" }}>
             {/* booking new changes comment starts here */}

             {/* {Translation[details.Languages]["Steps"]} {modalContent}/5 */}

             {/* booking new changes comment ends here */}
             {Translation[details.Languages]["Steps"]} {modalContent}/2
        </div>
        :""
        }
        {modalContent === 0 && modalNonKitchenContent===0?
        <span> 
            <LetsBegin />
            {props.applianceList && props.applianceList.length > 0?
            <div className={localStorage.getItem('language') == 'ar'? 'lets-both-btn-arabic' : 'lets-both-btn'}>
            <div className="lets-begin-button">
                <div className="lets-begin" onClick={handleNext}>
                {Translation[details.Languages]["Kitchen_booking"]}
                </div>
            </div>

            <div className="lets-begin-2-button">
                <div className="lets-begin-2" onClick={handleNonKitchenNext}>
                {Translation[details.Languages]["non_kitchen_booking"]}
                </div>
            </div>

            </div>
            :
            <div className={localStorage.getItem('language') == 'ar'? 'lets-both-btn-arabic' : 'lets-both-btn'}>
                
            <div className="lets-one-begin-button">
                <div className="lets-begin" onClick={handleNext}>
                {/* {Translation[details.Languages]["LetsBeginButton"]} */}
                {Translation[details.Languages]["Kitchen_booking"]}
                </div>
            </div>
            </div>
            }
        </span>
        
        :
        // modalContent === 1 && modalNonKitchenContent===0?
        // <span>
        //     <DesignKitchen designKitchen={designKitchen} handleNext={handleNext} orderData={dataOrder}/>
        // </span>
        // :
        // modalContent === 2 && modalNonKitchenContent===0?
        // <span>
        //     <ChooseKitchenStyle handleNext={handleNext} handlePrevious={handlePrevious} orderData={dataOrder}/>
            
        // </span>
        // :
        // modalContent === 3 && modalNonKitchenContent===0?
        // <span>
        //     <MoreInformation handlePrevious={handlePrevious} handleNext={handleNext} orderData={dataOrder}/>
        // </span>

        // :

        // modalContent === 4 && modalNonKitchenContent===0?

        // <span>
        //     <ReadyToMeet handlePrevious={handlePrevious} handleNext={handleNext} orderData={dataOrder} handlePreviousReadyToMeet={handlePreviousReadyToMeet}/>
        // </span>
        // :

        // modalContent === 5 && modalNonKitchenContent===0?
        // <span>
        //     <ConsultationMeetLocation handlePrevious={handlePrevious} handleNext={handleNext} handleClose={handleClose} referal_code={props.designerReference !== undefined? props.designerReference: ""} orderData={dataOrder} handlePreviousReadyToMeet={handlePreviousReadyToMeet} repeatOrder={props.repeatOrder}/>
        // </span>
        // :

// booking new changes start here
        modalContent === 1 && modalNonKitchenContent===0?

        <span>
            <ReadyToMeet handlePrevious={handlePrevious} handleNext={handleNext} orderData={dataOrder} handlePreviousReadyToMeet={handlePreviousReadyToMeet}/>
        </span>
        :

        modalContent === 2 && modalNonKitchenContent===0?
        <span>
            <ConsultationMeetLocation handlePrevious={handlePrevious} handleNext={handleNext} handleClose={handleClose} referal_code={props.designerReference !== undefined? props.designerReference: ""} orderData={dataOrder} handlePreviousReadyToMeet={handlePreviousReadyToMeet} repeatOrder={props.repeatOrder}/>
        </span>
        :
// booking new changes ends here

         modalNonKitchenContent===0?
        <span>
            <Confirmation noDesigner={noDesigner} handleNext={handleNext} handleClose={handleClose} onClickGotIt={onClickGotIt} type="kitchen"/>
        </span>
        :
        ''

        }

    {
        modalContent ===0 && modalNonKitchenContent === 1?
        <span>
            <NonKitchenInfo designKitchen={designKitchen} handleNext={handleNonKitchenNext} orderData={dataOrder}/>
        </span>
        :
        modalContent === 0 && modalNonKitchenContent === 2?

        <span>
            <ReadyToMeet handlePrevious={handleNonKitchenPrevious} handleNext={handleNonKitchenNext} orderData={dataOrder} handlePreviousReadyToMeet={handlePreviousReadyToMeetNonKitchen}/>
        </span>
        : 
        modalContent === 0 && modalNonKitchenContent === 3?
        <span>
            <ConsultationMeetLocation handlePrevious={handleNonKitchenPrevious} handleNext={handleNonKitchenNext} handleClose={handleClose} referal_code={props.designerReference !== undefined? props.designerReference: ""} orderData={dataOrder} handlePreviousReadyToMeet={handlePreviousReadyToMeetNonKitchen} repeatOrder={props.repeatOrder} otherOrder="otherOrder"/>
        </span>
        :
        modalContent === 0 && modalNonKitchenContent > 3?
        <span>
            <Confirmation noDesigner={noDesigner} handleNext={handleNonKitchenNext} handleClose={handleClose} onClickGotIt={onClickGotIt} type="otherOrder"/>
        </span>
        : ""
        
        }

        </Modal>
        <Meetdesignerswithslot meetdesignerModal={meetdesignerModal} closemeetdesignerModal={closemeetdesigner} />
    </div>
</React.Fragment>
);
}

export default BookFreeConsultation;