import React, {useState} from 'react';
import { useDispatch,useSelector } from "react-redux";
import PhoneImage from "../../images/Phoneicon.png"
import IntlTelInput from 'react-intl-tel-input';
import OtpInput from "react-otp-input";
import Translation from "../../language/translate"
import { ClipLoader } from 'react-spinners';
import {getOtpOfUpdatedNumber, verifyotp} from "../../actions/editProfileDetailsAndNumberAction"
import {logoutApp} from "../../actions/loginactions";
import { useHistory } from "react-router";
import "./index.css"

const ChangePhoneNumber=(props)=>{
    const history = useHistory();
    const details = useSelector(state => state);
    const dispatch = useDispatch();

    const [phonevalues, setgenerateotpmodule] = useState({
        error:"",
        validate_phone:false
      });
      const [otpvalues, setverifyotpmodule] = useState({
        otperror:"",
      });
    const [showOtpInputBox, setShowOtpInputBox] = useState(false)

    const typemobilenumber=(value, countriesData, defaultValue)=>{
        console.log("value countriesData", value)
         console.log(countriesData)
         console.log(defaultValue)
       var references = defaultValue;
       console.log("references", references)
       var countryCode = references.dialCode;
       var countryName = references.name;
       // console.log("countryCode", countryCode)
   
       var phoneNoWithoutAlpha = countriesData.replace(/[^0-9.]/g, "");
       if (value == true && countriesData != "") {
         setgenerateotpmodule(
           {
             phone: ` ${phoneNoWithoutAlpha}`,
             countryCode: `+${countryCode}`,
             countryName:countryName,
             displayPhone: "none",
             error:"",
             validate_phone:true
           });
       } else {
         setgenerateotpmodule({ 
           displayPhone: "block",
           error:"Please enter the valid phone number",
           validate_phone:false
        });
       }
}

const getOtp = async() =>{
    let obj ={
        "old_mobile_number": localStorage.getItem("mobile_no"),
        "mobile_number": phonevalues.phone.trim(),
        "country_code": phonevalues.countryCode,
        "userType":"customer",
        "token": localStorage.getItem("user_token")
    }
    const getOtpOfUpdatedNumberResponse = await dispatch(getOtpOfUpdatedNumber(obj));
    if(getOtpOfUpdatedNumberResponse.data.status_code == 200){
        setShowOtpInputBox(true)
    }
    else if(getOtpOfUpdatedNumberResponse.data.status_code == 403){
        // setShowOtpInputBox(false)
        setgenerateotpmodule({
            error:"Number already registered"
         });
    }
    else if(getOtpOfUpdatedNumberResponse.data.status_code == 408 || getOtpOfUpdatedNumberResponse.data.status_code == 402){
        let obj = {
            "customer_id": localStorage.getItem("customer_id"),
            "deviceType": "web"
        }
        const logoutResponse = await dispatch(logoutApp(obj));
        if(logoutResponse.data.status_code == 200){
            
            localStorage.clear();
        
            history.push({pathname:  "/"})
            window.location.reload()
            
            
        }
    }
}

const  fetchallotpvalues=async()=>{
    if(otpvalues.otpinputvalues==undefined || otpvalues.otplength<6){
        setverifyotpmodule({
                otperror:"Please enter all the values"
            })
    }
    else{
        let obj={
            "old_mobile_number": localStorage.getItem("mobile_no"),
            "mobile_number": phonevalues.phone.trim(),
            "mobileOTP": otpvalues.otpinputvalues,
            "deviceType":"web",
            "token": localStorage.getItem("user_token")
        }
        const verifyOtpResponse = await dispatch(verifyotp(obj));
        if(verifyOtpResponse.data.status_code == 200){
            localStorage.setItem('mobile_no', verifyOtpResponse.data.details.mobile_number)
            localStorage.setItem('country_code', verifyOtpResponse.data.details.country_code)
            props.handleClose()
        }
        else if(verifyOtpResponse.data.status_code == 406){
            setverifyotpmodule({
                otperror:"Invalid otp or mobile number"
            })
        }
        else if(verifyOtpResponse.data.status_code == 408 || verifyOtpResponse.data.status_code == 402){
            let obj = {
                "customer_id": localStorage.getItem("customer_id"),
                "deviceType": "web"
            }
            const logoutResponse = await dispatch(logoutApp(obj));
            if(logoutResponse.data.status_code == 200){
                
                localStorage.clear();
            
                history.push({pathname:  "/"})
                window.location.reload()
                
                
            }
        }
    }
}

const triggerOtp=(e)=>{
    console.log(e.length)
    setverifyotpmodule({
        otpinputvalues:e,
        otplength:e.length
    })
}

const handleClose=()=>{
    props.handleClose()
    setShowOtpInputBox(false)
}
return (
<React.Fragment>
    <div align={details.Languages == "ar"?"right":""}>
    <div className="row welcome-div">
        <div className="col-2 editprf_resp" style={{margin: "auto"}}>
            <img src={PhoneImage} alt="" />
        </div>
        <div className="col-10 prfdet_resp">
            <div className="welcome-name">
            {Translation[details.Languages]["ChangeNumber"]}
            </div>
            <div className="welcome-text">
            {Translation[details.Languages]["PhoneAlert"]} 
            </div>
        </div>
    </div>
    {!showOtpInputBox?
    <div style={{padding: "5% 10%"}}>
    <div className="mobile-heading">
    {Translation[details.Languages]["EnterMobileNumber"]}
    </div>
    <div className="inputfield">
    <IntlTelInput
        fieldId="phone"
        defaultCountry="in"
        containerClassName="intl-tel-input"
        inputClassName={details.Languages=="en"?"form-control tel_appnuii":"form-control tel_appnuiiarabic"}
        placeholder={Translation[details.Languages]["mobilenochange"]}
        onPhoneNumberChange={typemobilenumber}
        />
        <div className="phoneerror" style={{textAlign: "left", paddingBottom: "2%",width:"100%",float:"left"}}>{phonevalues.error}</div>
        </div>
        <div className="row button-wrap-div">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="cancel-button" onClick={()=> handleClose()}>
                {Translation[details.Languages]["Cancel"]}
                </div>
            </div> 
            <div className="col-lg-6 col-md-6 col-sm-12" >
                <input className="getotp-button" type="button" value={Translation[details.Languages]["GetOtp"]} onClick={()=> getOtp()} disabled={!phonevalues.validate_phone}/>
            </div>
        </div>
        <div className="otp_txtapp fix_otp" style={details.Languages == "en"?{textAlign: "left"}:{textAlign: "right"} }>{Translation[details.Languages]["SendingOtp"]}</div>
    </div>
    :
    <div className="row">
    <div style={{padding: "10%"}}>
        <div className="mobile-heading" style={{margin: "0"}}>
            Verification
        </div>
        <div className="otp_txtapp" style={details.Languages == "en"?{textAlign: "left"}:{textAlign: "right"} }>{Translation[details.Languages]["OtpNumber"]}</div>
        <div className="app_std">
                    <div className="phn_appwy" style={{textAlign: "left"}}>{phonevalues.countryCode}{phonevalues.phone}</div>
                    <div className="phn_appwychange" onClick={()=>setShowOtpInputBox(false)}>{Translation[details.Languages]["ChangePhoneNumber"]}</div>
                </div>
        <div className="app_std">
                <div className="otp_text_field-change"> 
                <OtpInput
                onChange={triggerOtp} 
                numInputs={6}
                name="otp"
                value={otpvalues.otpinputvalues}
                inputStyle="change_otp_fld_niu"
                // containerStyle="inputTextOtp"
                focusStyle="focusOtpField"
                separator={<div> </div>}
              />
                </div>
                <div className="app_std">
               
                <div className="row otp-button-wrap-div">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="cancel-button" onClick={()=> handleClose()}>
                {Translation[details.Languages]["Cancel"]}
                </div>
            </div> 
            <div className="col-lg-6 col-md-12 col-sm-12" >
                <input className="getotp-button" type="button" value="VERIFY" onClick={fetchallotpvalues}/>
            </div>
        </div>
                <div className="phoneerror" style={{textAlign: "left", margin: "10px 0"}}>{otpvalues.otperror}</div>
                {/* <div className="phoneerror">{props.otpvalueserror}</div>
                <div className="phoneerrorsucess">{props.otpsuccessmsg}</div> */}
                <div className="otp_txtappresend" onClick={()=>getOtp()}>{Translation[details.Languages]["ResendOTP"]}</div>
                </div>
            </div> 
    </div>
    </div>
  
    }
      </div>
</React.Fragment>
);
}

export default ChangePhoneNumber;